import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 2rem 0 2rem 0;
  text-align: center;
`;

export const Logo = styled.img`
  border-right: 2px solid #dedce4;
  padding-right: 1rem;
`;

export const LogoTE = styled.img`
  padding-right: 1rem;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  text-align: left;

  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1.5rem;
  flex: 1 1 auto;
`;
