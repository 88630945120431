import * as snippet from '@segment/snippet';
import { ENV } from '@utils/env-vars';

export function renderSegmentSnippet(): string {
  const opts: snippet.Options = {
    apiKey: ENV.segmentApiKey || '',
    page: false,
    useHostForBundles: true,
    host: ENV.segmentCustomCDN,
    load: !ENV.segmentCustomProxyUrl
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
