module.exports = {
  home: {
    page: '/index',
    langs: {
      es: '/'
    },
    params: ''
  },

  error_401: {
    page: '/401',
    langs: {
      es: '/401'
    },
    params: ''
  },

  error_404: {
    page: '/404',
    langs: {
      es: '/404'
    },
    params: ''
  },

  catalog: {
    page: '/catalog',
    langs: {
      es: '/catalogo'
    },
    params: ''
  },

  company: {
    page: '/company',
    langs: {
      es: '/empresa'
    },
    params: ''
  },

  createOffer: {
    page: '/offer',
    langs: {
      es: '/oferta'
    },
    params: ''
  },
  
  offer: {
    page: '/offer/:offerId',
    langs: {
      es: '/oferta/:offerId'
    },
    params: ''
  },

  candidates: {
    page: '/offer/:offerId/candidates',
    langs: {
      es: '/oferta/:offerId/candidatos'
    },
    params: ''
  },

  candidate: {
    page: '/offer/:offerId/candidates/:candidateId',
    langs: {
      es: '/oferta/:offerId/candidatos/:candidateId'
    },
    params: ''
  },

  notFound: {
    page: '/404',
    langs: {
      es: '/404'
    },
    params: ''
  },

  login: {
    page: '/api/auth/login',
    langs: {
      es: '/entra'
    },
    params: ''
  },

  logout: {
    page: '/api/auth/logout',
    langs: {
      es: '/logout'
    },
    params: ''
  }
};
