import { ButtonLink, Card, Header, Stack } from '@asgard/ui-library';
import { ErrorPage } from '@components/layout/ErrorPage';
import { faUserAstronaut } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from '@hooks/useI18n';
import { APP_PAGES, getPagePath } from '@utils/app-pages';
import { useRouter } from 'next/dist/client/router';
import { HtmlTrans } from '../HtmlTrans';
import { ErrorServerProps } from './ErrorServer.types';

// Component.
// -----------------------------------------------------------------------------
export const ErrorServer: React.FC<ErrorServerProps> = ({ message }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const router = useRouter();
  const { t } = useI18n('common');

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <ErrorPage>
      <Stack alignment="center" direction="column">
        <Card hideImage>
          <Header as="h2" size="3xl">
            {t('common.page_500.title')}
          </Header>
          <HtmlTrans
            i18nKey="common.page_500.desc"
            ns="common"
            components={{
              supportLink: (
                <a href="mailto:soporte@getmanfred.com" target="_blank" />
              ),
              talentEngineLink: (
                <a
                  href="https://www.getmanfred.com/talent-engine"
                  target="_blank"
                />
              ),
              salesTeamLink: (
                <a
                  href="https://www.getmanfred.com/talent-engine#talentEngineForm"
                  target="_blank"
                />
              )
            }}
            values={{
              error: message
            }}
          />
        </Card>

        <ButtonLink
          href={getPagePath(APP_PAGES.logout, router.locale)}
          label="Accede de nuevo a la plataforma"
          startIcon={faUserAstronaut}
          schema="neutral"
          variant="hard"
        />
      </Stack>
    </ErrorPage>
  );
};
