import { Stack } from '@asgard/ui-library';
import { useI18n } from '@hooks/useI18n';
import * as S from './ErrorPage.styles';

export const ErrorPage: React.FC = ({ children }) => {
  const { t } = useI18n('common');

  return (
    <S.Container>
      <Stack gap="xl" direction="row" alignment="center" contentAlign="center">
        <S.Logo
          src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
          width="150px"
        />

        <S.LogoTE
          src="https://talent-engine-media.s3.eu-west-1.amazonaws.com/assets/logo_talent_engine_color.svg"
          alt={t('common.page-title')}
          width="175px"
        />
      </Stack>

      <S.Content>{children}</S.Content>
    </S.Container>
  );
};
