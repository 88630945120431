import { useI18n } from '@hooks/useI18n';
import { APP_PAGES, getPagePath } from '@utils/app-pages';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { ErrorFallbackProps } from './ErrorFallback.types';

// Component.
// -----------------------------------------------------------------------------
export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ onRetry }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('errorPage');
  const router = useRouter();

  // Handlers.
  // ---------------------------------------------------------------------------
  const handleOnClick = useCallback(() => {
    onRetry();
    router.push(getPagePath(APP_PAGES.home, router.locale));
  }, [onRetry, router]);

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <>
      <h2>{t('errorFallback.heading')}</h2>
      <p>There was an error</p>
      <button onClick={handleOnClick}>Retry</button>
    </>
  );
};
