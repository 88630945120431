import { i18n } from '@/next-i18next.config';
import type { NextApiRequest } from 'next';
import { acceptLanguage } from 'next/dist/server/accept-header';

/**
 * This is just an alias over the `defaultLocale` value from `next-i18next.config.js`
 */
export const DEFAULT_LOCALE = i18n.defaultLocale;

/**
 * Get the locale for a given request:
 *
 * - If i18n is not configured, use the default locale
 * - If the user has chosen a locale (via NEXT_LOCALE cookie), use it
 * - Otherwise, use the first valid locale from the `accept-language` header
 * - If no locale is found, use the default locale
 *
 * @param req NextJS API Request
 *
 * @returns the request locale
 */
export function getLocaleForRequest(req: NextApiRequest): string {
  if (!i18n) return DEFAULT_LOCALE;

  const chosenLocale = i18n.locales.find(
    (locale) => locale === req.cookies.NEXT_LOCALE
  );

  const detectedLocale =
    chosenLocale ??
    acceptLanguage(req.headers['accept-language'], i18n.locales);

  return detectedLocale || DEFAULT_LOCALE;
}
