export class HttpError extends Error {
  public status: number | undefined;

  constructor(message?: string, status?: number) {
    super(message); // 'Error' breaks prototype chain here
    this.name = 'HttpError';
    this.status = status;
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}
