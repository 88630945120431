import * as envVars from '@/next.env-vars';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

type ConfigValue = string;

const getRuntimeVariable = (
  runtimeVariable: string,
  defaultValue?: ConfigValue
): ConfigValue => {
  const unvalidatedRuntimeVariable = publicRuntimeConfig[runtimeVariable];

  if (!unvalidatedRuntimeVariable) {
    if (defaultValue != null) {
      return defaultValue;
    }

    // ? We could throw an error here instead setting an empty value
    // * This would stop the execution, ensuring that the variable is available
    return '';
  } else {
    return unvalidatedRuntimeVariable;
  }
};

/**
 * Returns all the env vars for the app (from the `next.env-vars.js` file)
 *
 * @returns object containing all the env vars
 */
export const getAppEnvVars = (): Record<keyof typeof envVars, ConfigValue> => {
  const config: Record<string, ConfigValue> = {};

  Object.entries(envVars).forEach(([key, value]) => {
    config[key] = getRuntimeVariable(value.name, value.defaultValue);
  });

  return config;
};
