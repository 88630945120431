const path = require('path');

module.exports = {
  localePath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  react: {
    useSuspense: false
  },
  i18n: {
    locales: ['es'],
    defaultLocale: 'es',
    localeDetection: false
  },
};
