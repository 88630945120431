import appPages from '@/next.pages';
import { AppPagePath } from '@utils/app-pages.types';
import { DEFAULT_LOCALE } from './locale';

/**
 * Object containing the app pages config
 */
export const APP_PAGES: Readonly<Record<keyof typeof appPages, AppPagePath>> =
  appPages;

/**
 * Get the path URL for a given page and locale
 *
 * @param page The page
 * @param locale The locale to use
 *
 * @returns The path for the given page and locale
 */
export const getPagePath = (
  page: AppPagePath,
  locale = DEFAULT_LOCALE
): string => {
  return page.langs[locale.toLowerCase()];
};

/**
 * Get the path URL for the login page
 *
 * @param locale The locale to use
 *
 * @returns The path for the login page
 */
export const getLoginPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.login, locale)}`;
};

/**
 * Get the path URL for the home page
 *
 * @param locale The locale to use
 *
 * @returns The path for the home page
 */
export const getHomePagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.home, locale)}`;
};

/**
 * Get the path URL for the catalog page
 *
 * @param locale The locale to use
 *
 * @returns The path for the catalog page
 */
export const getCatalogPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.catalog, locale)}`;
};

/**
 * Get the path URL for the create offer page
 *
 * @param locale The locale to use
 *
 * @returns The path for the create offer page
 */
export const getCreateOfferPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.createOffer, locale)}`;
};

/**
 * Get the path URL for a given Offer page
 *
 * @param offerId The Offer Id
 * @param locale The locale to use
 *
 * @returns The path for the given Offer Id and locale
 */
export const getOfferPagePath = (
  offerId: string | number,
  locale = DEFAULT_LOCALE,
  tab?: string
): string => {
  return `${getPagePath(APP_PAGES.offer, locale)}${
    tab ? `?tab=${tab}` : ''
  }`.replace(':offerId', offerId.toString());
};
