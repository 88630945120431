import { ENV } from '@utils/env-vars';
import { renderSegmentSnippet } from '@utils/segment';
import Script from 'next/script';

export const SegmentScript: React.FC = () => {
  return (
    <>
      {ENV.segmentApiKey && (
        <Script
          id="segment-script"
          dangerouslySetInnerHTML={{
            __html: renderSegmentSnippet()
          }}
        />
      )}

      {ENV.segmentApiKey && ENV.segmentCustomProxyUrl && (
        <Script
          id="segment-config-script"
          dangerouslySetInnerHTML={{
            __html: `analytics.load("${ENV.segmentApiKey}", { integrations: { "Segment.io": { apiHost: "${ENV.segmentCustomProxyUrl}/v1" }}})`
          }}
        />
      )}
    </>
  );
};
