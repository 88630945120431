import { getErrorMessage } from './errors';
import { HttpLogArgs, LogArgs, LogErrorArgs } from './log.types';

/**
 * @deprecated Use `Logger.error` instead
 */
export const logError = (args: LogErrorArgs): void => error(args);

const http = ({ method, path, data }: HttpLogArgs): void => {
  if (data) {
    console.log({
      http: { method: method, url: path },
      message: `▶️ ${method || '<NONE>'} ${path || '<NONE>'}`,
      data
    });
  } else {
    console.log({
      http: { method: method, url: path },
      message: `▶️ ${method || '<NONE>'} ${path || '<NONE>'}`
    });
  }
};

const log = ({ context, message, object }: LogArgs): void => {
  if (object) {
    console.log(`[${context}] ${message}`, object);
  } else {
    console.log(`[${context}] ${message}`);
  }
};

const debug = ({ context, message, object }: LogArgs): void => {
  if (object) {
    console.debug(`🔎 [${context}] ${message}`, object);
  } else {
    console.debug(`🔎 [${context}] ${message}`);
  }
};

const info = ({ context, message, object }: LogArgs): void => {
  if (object) {
    console.info(`ℹ️ [${context}] ${message}`, object);
  } else {
    console.info(`ℹ️ [${context}] ${message}`);
  }
};

const warn = ({ context, message, object }: LogArgs): void => {
  if (object) {
    console.warn(`🚧 [${context}] ${message}`, object);
  } else {
    console.warn(`🚧 [${context}] ${message}`);
  }
};

const error = ({ context, message, error, object }: LogErrorArgs): void => {
  if (object) {
    console.error(
      `🚨 [${context}] ${message}. Error: ${getErrorMessage(error)}`,
      error,
      object
    );
  } else {
    console.error(
      `🚨 [${context}] ${message}. Error: ${getErrorMessage(error)}`,
      error
    );
  }
};

export const Logger = {
  http,
  log,
  debug,
  info,
  warn,
  error
};
